import { ColumnPassThroughAttributes } from 'primevue/column';
import { PrimeVuePTOptions } from 'primevue/config';
import { DataTableContext, DataTableProps } from 'primevue/datatable';

export const DataTableDesign = {
    datatable: {
        root: {},
        column: {
            bodyCell: {
                class: `text-left text-sm border-0 border-b border-l border-r border-solid border-gray-300 
                pl-4 pt-4 pb-2`,
            },
            headerCell: {
                class: `text-left text-sm border-0 border-b border-b-neutral-600 border-solid border-gray-300 
                font-semibold transition duration-200 p-4 bg-white text-slate-700`,
            },
        } as ColumnPassThroughAttributes,
        bodyRow: ({ context }: { props: DataTableProps; context: DataTableContext }) => {
            if (!context.stripedRows) return '';
            return { class: context.index % 2 === 0 ? 'bg-neutral-100' : 'bg-white' };
        },
    },
} as Pick<PrimeVuePTOptions, 'datatable'>;
