<template>
    <DataTable
        :value="reactivePageData.emds"
        data-key="id"
        @row-click="toggleRowExpand"
        @animationend="onAnimationEnd"
        v-model:expanded-rows="expandedRows"
        :pt="{ rowExpansionCell: 'border-transparent', emptyMessageCell: 'border-transparent this-has-no-effect' }"
        :table-class
        :row-class="getRowClasses"
        class="md:pr-6 xs:pr-2 max-w-screen-2xl m-auto font-inter"
    >
        <template #empty>
            <p class="empty-emd-table p-4 text-lg text-center text-neutral-500">
                Early Deposit requests you create will appear here.
            </p>
        </template>
        <template
            v-if="pageFetchError"
            #header
        >
            <p class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
                There was an error fetching data for this table. Please try again, or contact support if you continue to
                experience the problem.
            </p>
        </template>
        <Column
            field="address"
            header="Property Address"
            body-class="text-lg font-medium border-x-transparent cursor-pointer"
            class="address-col"
        >
            <template #body="{ data: rowData }: { data: EarlyDeposit }">
                <div class="flex items-center show-expand-toggle">
                    {{ rowData.address }}
                </div>
            </template>
        </Column>
        <Column
            field="deposit_amount"
            header="Requested Amount"
            body-class="border-x-transparent cursor-pointer"
        >
            <template #body="{ data }">
                <div class="flex items-center">
                    {{ formatMoney(data.deposit_amount / 100) }}
                </div>
            </template>
        </Column>

        <Column
            field="branch.title.name"
            header="Company"
            body-class="border-x-transparent cursor-pointer"
        />
        <Column
            field="branch.name"
            header="Branch"
            body-class="border-x-transparent cursor-pointer"
        />
        <Column
            field="created_at"
            header="Created"
            body-class="border-x-transparent cursor-pointer"
            class="created-col"
        >
            <template #body="{ data: rowData }: { data: EarlyDeposit }">
                {{ formatDate(rowData.created_at, MONTH_DAY_YEAR_FORMAT, rowData.created_at) }}
            </template>
        </Column>
        <Column
            header="Actions"
            body-class="border-x-transparent"
            class="actions-col"
            ><template #body="{ data: rowData }: { data: EarlyDeposit }">
                <div class="flex justify-end gap-1 px-2 dim-other-btns">
                    <button
                        class="px-1 options-icon shadow-none transition-opacity duration-150 text-primary"
                        @click.stop="setUpdateRef(rowData)"
                        title="Update early deposit"
                    >
                        <i class="fa-edit fas" />
                    </button>
                    <button
                        class="px-1 options-icon shadow-none transition-opacity duration-150 text-danger"
                        @click.stop="setDeleteRef(rowData)"
                        title="Delete early deposit"
                    >
                        <i class="fa-times fas" />
                    </button>
                </div>
            </template>
        </Column>
        <template #expansion="{ data: rowData, index }: { data: EarlyDeposit; index: number }">
            <EarlyDepositTableRowDetails
                :row-data
                :key="index"
            />
        </template>
    </DataTable>

    <EarlyDepositTablePaginator v-if="reactivePageData.emds.length > 0" />

    <UpdateEarlyDepositModal @emd-updated="onEmdUpdated" />

    <DeleteEarlyDepositModal @emd-deleted="onEmdDeleted" />
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import {
    useDeleteEarlyDeposit,
    useEarlyDepositsPaged,
    useExpandedRows,
    useUpdateEarlyDeposit,
} from '@/services/early-deposit.service';
import { formatDate, MONTH_DAY_YEAR_FORMAT } from '@/shared/utils/format-date';
import Column from 'primevue/column';
import type { DataTableRowClickEvent } from 'primevue/datatable';
import DataTable from 'primevue/datatable';
import { reactive, watch } from 'vue';
import DeleteEarlyDepositModal from './DeleteEarlyDepositModal.vue';
import EarlyDepositTablePaginator from './EarlyDepositTablePaginator.vue';
import EarlyDepositTableRowDetails from './EarlyDepositTableRowDetails.vue';
import UpdateEarlyDepositModal from './UpdateEarlyDepositModal.vue';
import { formatMoney } from '@/shared/utils/format-number';

interface EarlyDepositClickEvent extends DataTableRowClickEvent {
    data: Pick<EarlyDeposit, 'id'>;
}

const { setDeleteRef } = useDeleteEarlyDeposit();
const { setUpdateRef } = useUpdateEarlyDeposit();

const { reactivePageData, pageFetchError, updateLocalEmd, refetchCurrentPage } = useEarlyDepositsPaged();
const { expandedRows, refreshExpandedRows } = useExpandedRows();

const tableClass = 'view-emd-table';
watch(
    () => reactivePageData.emds,
    (newPage, oldPage) => {
        if (newPage?.length && oldPage?.length) {
            requestAnimationFrame(() => {
                document.querySelector(`.${tableClass}`)?.scrollIntoView({ behavior: 'smooth' });
            });
        }
    },
);

const highlightModifiedRow: { id: EarlyDeposit['id']; operation: '' | 'delete' | 'update' } = reactive({ id: '', operation: '' });

const onEmdDeleted = (deletedEmdId: EarlyDeposit['id']) => {
    highlightModifiedRow.id = deletedEmdId;
    highlightModifiedRow.operation = 'delete';
    delete expandedRows.value[deletedEmdId];
};

const onEmdUpdated = ({ id, address, deposit_amount }: EarlyDeposit) => {
    highlightModifiedRow.id = id;
    highlightModifiedRow.operation = 'update';
    updateLocalEmd({ id, address, deposit_amount });
    expandedRows.value = { ...expandedRows.value, refreshTable: true };
    delete expandedRows.value.refreshTable;
};

const REFETCH_PAGE = 'refetch-page';
const onAnimationEnd = async ({ animationName, target }: AnimationEvent) => {
    const isRowModAnimation = ['emd-updated', 'emd-deleted'].some(name => animationName.startsWith(name));
    if (!isRowModAnimation) return;

    if ((target as HTMLElement).classList.contains(REFETCH_PAGE)) {
        await refetchCurrentPage();
        refreshExpandedRows();
    }
    highlightModifiedRow.id = '';
    highlightModifiedRow.operation = '';
};

const toggleRowExpand = (arg: Pick<EarlyDepositClickEvent, 'data'>) => {
    const rowId = arg.data.id;
    const expandedRowsCopy = { ...expandedRows.value };

    if (rowId in expandedRowsCopy) delete expandedRowsCopy[rowId];
    else expandedRowsCopy[rowId] = true;

    expandedRows.value = expandedRowsCopy;
};

const getRowClasses = (rowData: EarlyDeposit) => {
    const RECENTLY_DELETED_CSS = `${REFETCH_PAGE} play-emd-deleted`;
    const RECENTLY_UPDATED_CSS = 'play-emd-updated';
    const modifiedId = highlightModifiedRow.id;
    const operation = highlightModifiedRow.operation;
    return {
        [`transition-color duration-100 row-id-${rowData.id}`]: true,
        'is-expanded-row': rowData.id in expandedRows.value,
        'hover:bg-blue-100/50': rowData.id !== modifiedId, // avoid having hover style clobber animation
        [RECENTLY_DELETED_CSS]: operation === 'delete' && rowData.id === modifiedId,
        [RECENTLY_UPDATED_CSS]: operation === 'update' && rowData.id === modifiedId,
    };
};
</script>

<style lang="scss" scoped>
:deep(.view-emd-table) {
    .actions-col {
        width: 6ch;
    }

    .play-emd-deleted,
    .play-emd-deleted + [data-pc-section='rowexpansion'] {
        animation: emd-deleted 1s forwards;
    }

    .play-emd-updated {
        animation: emd-updated 2s;
    }
}

@keyframes emd-deleted {
    from {
        @apply bg-rose-100;
    }

    to {
        @apply line-through;
        opacity: 0;
    }
}

@keyframes emd-updated {
    50% {
        @apply bg-emerald-100;
    }

    100% {
        background-color: initial;
    }
}

.dim-other-btns:has(button:hover) {
    button:not(:hover) {
        opacity: 0.5;
    }
}

.show-expand-toggle::before {
    --content: '+';
    --bg-color: #0b0;

    content: var(--content);
    cursor: pointer;
    font: 0.75em/1 monospace;
    display: flex;
    align-items: center;
    border-radius: 100%;
    background-color: var(--bg-color);
    color: white;
    margin-right: 1ch;
    padding: 0.25em;
    padding-bottom: 0.3em; // better visual centering
    height: 1.15em;
    width: 1.15em;
    transition: background-color 150ms linear;
}

.is-expanded-row .show-expand-toggle::before {
    --content: '-';
    --bg-color: #b00;
}

.empty-emd-table {
    word-spacing: 0.05ch;
}
</style>
